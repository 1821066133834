.user-list,
.project-list {
    margin: 20px 0;
}

.add-user,
.add-project {
    margin-top: 30px;
}

.add-user input,
.add-project input {
    color: black;
    margin: 10px;
    padding: 10px;
    width: 200px;
}